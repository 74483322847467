import '../styles/index.scss';

window.onload = function () {
	let instructions_button = document.querySelector('.button--instructions');
	let instructions_container = document.querySelector('.instructions-container');
	instructions_button.addEventListener('click', function(){
		if (!instructions_container.classList.contains('active')) {
			instructions_container.classList.add('active');
		} else {
			instructions_container.classList.remove('active');
		}
	});
	let privacy_button = document.querySelector('.button--privacy');
	let privacy_container = document.querySelector('.privacy-container');
	let download_button = document.querySelector('.button--download');
	privacy_button.addEventListener('click', function(){
		if (!privacy_container.classList.contains('active')) {
			privacy_container.classList.add('active');
		} else {
			privacy_container.classList.remove('active');
		}
		if (!download_button.classList.contains('active')) {
			download_button.classList.add('active');
			download_button.style.pointerEvents = 'auto';
			download_button.href = 'https://yung-hurn-ar-player-app.livefromearth.de/downloads/yung_hurn_AR_Player_Android.apk';
		}
	});
};